import React, { useMemo } from "react"
import Image from "gatsby-image"
import { graphql } from "gatsby"
import tw from "twin.macro"
import {
  PageLayout,
  BaseMDXProvider,
  H2,
  Caption,
  H5,
  GlossarySidebarProvider,
  SectionHeader,
  InlinePhoto,
  QuoteImagePhoto,
  FullPhoto,
  QuotePhoto,
  GlossaryTermLink,
  PhotoModuleImagesProvider,
  Tagline,
  InlineVideo,
  SubsectionHeader,
  Container,
  PrimaryContent,
} from "../components"
import { cssMq } from "../components/mediaquery"
import {
  useExtractSectionHeadersData,
  useGetIntraPageNavIsVisible,
  useGetNodeFilePublicUrl,
} from "./utils"
import { MDXRenderer } from "gatsby-plugin-mdx"

const components = {
  SectionHeader,
  InlinePhoto,
  QuoteImagePhoto,
  FullPhoto,
  QuotePhoto,
  GlossaryTermLink,
  InlineVideo,
  SubsectionHeader,
  inlineCode: ({ children, ...props }) => {
    return (
      <span
        className="text-faded italic"
        css={{ fontFamily: "inherit", fontSize: "inherit" }}
        {...props}
      >
        {children}
      </span>
    )
  },
}

export default function ArticleContentTemplate({
  data: {
    mdx: { frontmatter, body: children, mdxAST },
    allFile,
  },
}) {
  const images = useMemo(() => allFile.nodes
    .map(node => node?.childImageSharp?.fluid)
    .filter(src => !!src), []) // prettier-ignore
  const leadImageSrc = images.filter(src => src.originalName === frontmatter.leadImage)[0] // prettier-ignore
  const seoImage = useMemo(() => images.filter(src => src.originalName === frontmatter.socialRefers.image)[0], []) // prettier-ignore
  const sectionHeadersData = useExtractSectionHeadersData(mdxAST)

  const pdfFilePath = useGetNodeFilePublicUrl(frontmatter.pdfFile, allFile)
  const audioFilePath = useGetNodeFilePublicUrl(frontmatter.audioFile, allFile)

  const contentContainerRef = React.useRef()
  const [referenceElementRef, navIsVisible] = useGetIntraPageNavIsVisible(
    refElement => refElement.offsetTop - 40
  )

  return (
    <PageLayout
      dark
      title={frontmatter.title}
      sectionsData={sectionHeadersData}
      contentContainerRef={contentContainerRef}
      navIsVisible={navIsVisible}
      seoData={{
        description: frontmatter.introduction,
        image: seoImage ?? leadImageSrc,
      }}
    >
      <div
        ref={referenceElementRef}
        className="bg-black w-full border-t border-white"
        css={cssMq({
          height: ["auto", "auto", "auto", "calc(100vh - 120px)"],
        })}
      >
        <div
          tw="flex lg:flex flex-col lg:flex-row h-full"
          css={cssMq({
            background: [
              "",
              "",
              "",
              "linear-gradient(#FFF, #FFF) no-repeat center/1px 100%",
              "linear-gradient(#FFF, #FFF) no-repeat center/1px 100%",
            ],
          })}
        >
          {leadImageSrc && (
            <Image
              fluid={leadImageSrc}
              className="lg:w-1/2"
              css={{ "@media print": { display: "none" }, marginRight: "1px" }}
            />
          )}
          <div
            tw="flex lg:w-1/2 mt-6 md:mt-14 lg:mt-0 text-white text-center"
            css={{
              "@media print": { color: "#000", width: "100% !important" },
              marginLeft: "1px",
            }}
          >
            <div
              className="mx-6 sm:mx-auto lg:my-auto"
              css={cssMq({
                maxWidth: ["100%", "520px", "560px", "700px", "700px"],
                width: ["auto", "auto", "auto", "80%", "80%"],
              })}
            >
              <Tagline
                className="mb-10 lg:mb-14 border-t border-b border-solid border-white"
                css={{ "@media print": { borderColor: "#000" } }}
              >
                <span className="font-bold">{frontmatter.topicArea}</span>
                &nbsp;/&nbsp;
                {frontmatter.contentType}
              </Tagline>
              <H2 tw="mb-10">{frontmatter.headline}</H2>
              <H5 className="mb-10 lg:mb-14">{frontmatter.introduction}</H5>
            </div>
          </div>
          <div
            className="lg:hidden h-14"
            css={{
              background:
                "linear-gradient(#FFF, #FFF) no-repeat center/1px 100%",
            }}
          />
        </div>
      </div>
      <div
        className="h-10 lg:h-16"
        css={{
          background: "linear-gradient(#000, #000) no-repeat center/1px 100%",
        }}
      />
      <Container className="mb-10">
        <Caption
          as={PrimaryContent}
          className="border-t border-b border-black py-4 flex flex-col justify-center text-center"
          css={{ "@media print": { borderColor: "#000" } }}
        >
          <div className="mb-1 lg:mb-0">
            <span className="text-faded">
              {frontmatter.bylines?.first?.title} —
            </span>
            &nbsp;
            <a
              className="hover:underline"
              href={frontmatter.bylines?.first?.link}
            >
              {frontmatter.bylines?.first?.name}
            </a>
          </div>
          <div className="mb-1 lg:mb-0">
            {frontmatter.bylines?.second?.title && (
              <span className="text-faded">
                {frontmatter.bylines?.second?.title} —
              </span>
            )}
            &nbsp;
            <a
              className="hover:underline"
              href={frontmatter.bylines?.second?.link}
            >
              {frontmatter.bylines?.second?.name}
            </a>
          </div>
          {frontmatter.date && (
            <div>
              <span className="text-faded">{frontmatter.date}</span>
            </div>
          )}
        </Caption>
      </Container>
      <PhotoModuleImagesProvider images={images}>
        <GlossarySidebarProvider>
          <BaseMDXProvider
            ref={contentContainerRef}
            components={components}
            socialRefers={{
              ...frontmatter.socialRefers,
              pdfFilePath,
              audioFilePath,
            }}
          >
            <MDXRenderer>{children}</MDXRenderer>
          </BaseMDXProvider>
        </GlossarySidebarProvider>
      </PhotoModuleImagesProvider>
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query ArticleContentQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      mdxAST
      frontmatter {
        title
        headline
        leadImage
        topicArea
        contentType
        headline
        introduction
        date
        pdfFile
        audioFile
        bylines {
          first {
            title
            name
            link
          }
          second {
            title
            name
            link
          }
        }
        socialRefers {
          title
          notes
          shareText
          image
        }
      }
    }
    allFile(filter: { relativeDirectory: { eq: "contents" } }) {
      nodes {
        name
        ext
        publicURL
        childImageSharp {
          fluid(sizes: "100vw") {
            ...GatsbyImageSharpFluid
            originalName
            presentationWidth
            presentationHeight
          }
        }
      }
    }
  }
`
